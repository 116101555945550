<template>
  <div class="modal team">
    <div class="box one mHeader flexB">
      <h1>{{ $t("site-view-summary-system-notice-detail")}}</h1>
      <a @click="close"><i class="fas fa-times"></i></a>
    </div>
    <div class="box one mBody">
      <div class="tableWrap">
        <colgroup>
          <col style="width: 20%" />
          <col style="width: 80%" />
        </colgroup>
        <table class="appInfo">
          <tr>
            <th>{{$t("user-home-user-system-notice-title")}}</th>
            <td>
              <input type="text" style="width: 100%; font-size: 1.2rem; padding: 0 10px; margin-bottom: 0;" v-model="title"
                     :placeholder="[$t('site-view-summary-system-notice-please-input-title')]" />
            </td>
          </tr>
          <tr>
            <th>{{$t("user-home-user-system-notice-contents")}}</th>
            <td>
              <textarea style="width: 100%; height: 200px; padding: 5px 10px; font-size: 1.2rem; border: 1px solid #d3d3d3; margin: 5px 0; resize: none;"
                        v-model="content" :placeholder="[$t('site-view-summary-system-notice-please-input-content')]"></textarea>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <template>
          <div class="container" style="margin-top: 10px;">
            <!-- 파일 업로드 -->
            <input type="file" ref="fileInput" class="file-upload-input" @change="onFileChange" multiple>
            <!-- 업로드된 리스트 -->
            <div class="file-upload-list">
              <div class="file-upload-list__item" v-for="(data, i) in fileList" :key="i">
                <!-- 업로드된 리스트 -->
                <div class="file_upload_form">
                  <span v-if="editMode">{{ data.originFileName }}</span>
                  <span v-else>{{ data.name }}</span>
                  <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon" style="width: 1.5rem; height: 1.5rem; margin-left: 12px;" @click="handleFileForFileList(i, data.originFileName, data.referenceContentsId)"/>
                </div>
<!--                <div class="file-upload-list__item__data">
                  <img class="file-upload-list__item__data-thumbnail" >
                  <div class="file-upload-list__item__data-name">
                    {{ data.name }}
                  </div>
                </div>
                <div class="file-upload-list__item__btn-remove" @click="handleRemove(i)">
                  삭제
                </div>-->
              </div>
            </div>
            <div class="file-upload-list">
              <div class="file-upload-list__item" v-for="(data, im) in fileListToModify" :key="im">
                <div class="file_upload_form">
                  <span>{{ data.name }}</span>
                  <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon" style="width: 1.5rem; height: 1.5rem; margin-left: 12px;" @click="handleRemoveForModifyList(im)"/>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
      <div class="buttonWrap">
        <button v-if="editMode" class="point large" @click="patch">{{ $t("btn-data-patch") }}</button>
        <button v-else class="point large" @click="submit">{{ $t("btn-register") }}</button>
        <button class="large margin6" @click="close">{{ $t("btn-cancel") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createNoticeApp,
  createNoticeAppFileUpload,
  fetchNoticeAppFileListInfo,
  fetchNoticeAppInfo,
  updateNoticeAppInfo
} from "@/api/event";
import { fetchDeleteVod } from "@/api/contents";

export default {
  props : {
    memo : {
      type : String,
      default : null,
      file: "",
      fileSrc:"",
      fileList:[],
    },
    id:{
      type:String,
      default: null,
    },
    noticeAppId:{
      type: String,
    }
  },
  data() {
    return {
      title: "",
      content: "",
      fileList: [], //수정시: 기존 파일만 여기에 담는다.
      fileListToModify: [], //수정시 새로 추가된 파일만 여기에 담는다.
      editMode: false,
    };
  },
  mounted() {
    if(this.noticeAppId != null){
      this.editMode = true;
      this.getNoticeAppInfo();
      this.getFileListInfo();
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      if(this.title === ""){
        return alert(this.$t("site-view-summary-system-notice-please-input-title"));
      }else if(this.content === ""){
        return alert(this.$t("site-view-summary-system-notice-please-input-content"));
      }

      let noticeAppData = {
        title: this.title,
        content: this.content,
      };

      createNoticeApp(noticeAppData).then((res) => {
        if (res.data.result === 0){
          this.uploadFile(this.fileList, res);
        }else{
          let message = res.data.message;
          alert(message);
          this.close();
        }
      });
    },
    handleRemove (index) {
      this.fileList.splice(index, 1)
    },
    handleRemoveForModifyList (index) {
      this.fileListToModify.splice(index, 1)
    },
    handleFileForFileList(index, fileName, referenceContentsId){
      if(this.editMode){
        if(confirm(this.$t("site-view-summary-system-notice-ask-delete-file")+"\n"+fileName)){
          fetchDeleteVod(referenceContentsId).then((res)=>{
            if (res.data.result === 0){
              alert(this.$t("alert-message-delete"));
              this.getFileListInfo();
            }
          })
        }
      }else{
        this.handleRemove (index);
      }
    },
    onFileChange(e){
      this.file = e.target.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.fileSrc = e.target.result;
      };
      if(this.editMode){
        this.fileListToModify.push(this.file);
      }else{
        this.fileList.push(this.file);
      }
    },
    getNoticeAppInfo(){
      fetchNoticeAppInfo(this.noticeAppId).then((res)=>{
        this.title = res.data.data.title;
        this.content = res.data.data.content;
      });
    },
    getFileListInfo(){
      fetchNoticeAppFileListInfo(this.noticeAppId).then((res)=>{
        this.fileList = res.data.data;
      });
    },
    patch(){
      if(this.title === ""){
        return alert(this.$t("site-view-summary-system-notice-please-input-title"));
      }else if(this.content === ""){
        return alert(this.$t("site-view-summary-system-notice-please-input-content"));
      }

      let data = {
        title: this.title,
        content: this.content,
      };
      updateNoticeAppInfo(this.noticeAppId, data).then((res)=>{
        if (res.data.result === 0){
          this.uploadFile(this.fileListToModify,res);
        }else{
          let message = res.data.message;
          alert(message);
          this.close();
        }
      });
    },
    uploadFile(listOfFiles, noticeAppInfo){
      if(listOfFiles.length > 0){
        let fileUploadData = new FormData();

        fileUploadData.append("noticeAppId", noticeAppInfo.data.data.noticeAppId);
        listOfFiles.forEach( (eachFile) => {
          fileUploadData.append("files", eachFile);
        });
        let uploadFailMessage = "";

        createNoticeAppFileUpload(fileUploadData).then((fileUploadRes) => {
          let uploadResultData = fileUploadRes.data.data;
          uploadResultData.forEach((eachRes) => {
            if(eachRes.content == null){
              uploadFailMessage = uploadFailMessage+ "["+eachRes.fileName+", "+eachRes.message+"]\n";
            }
          });
          alert(this.$t("alert-message-data-save"));
          if(uploadFailMessage !== ""){
            alert(this.$t("590")+" "+uploadFailMessage);
          }
          this.close();
          this.$router.go();
        });
      }else{
        alert(this.$t("alert-message-data-save"));
        this.close();
        this.$router.go();
      }
    },
  }
};
</script>

